import storage from './storage'
import {
    STORAGE_KEYS
} from './constants'

export var temp = {}

export var globalData = {

}

export function storeCartId(cartId) {
    storage.set(STORAGE_KEYS.cartId, cartId)
}

export function getCartId() {
    return storage.get(STORAGE_KEYS.cartId)
}

export function storeRecentSearch(recentSearch) {
    storage.set(STORAGE_KEYS.recentSearch, recentSearch)
}

export function getRecentSearch() {
    return storage.get(STORAGE_KEYS.recentSearch)
}