import gql from "graphql-tag";

export const searchProducts = gql `
query searchProducts($query: String){
  products(first: 10, query: $query) {
    edges {
      node {
        id
        title
        featuredImage {
          url
        }
        priceRange {
          maxVariantPrice {
            amount
          }
          minVariantPrice {
            amount
          }
        }
        metafields(first: 10) {
          edges {
            node {
              key
              value
            }
          }
        }
      }
    }
  }
}
`