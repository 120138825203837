import {
    query
} from "@/utils/apollo-utils.js"
import axios from '@/utils/request';

import {
    searchProducts as searchProductsGql,
} from "./gqls.js"

export async function searchProducts(payload) {
    return axios.post('shopify/query/products', payload, {
        'Content-Type': 'application/x-www-form-urlencoded'
    });
}
