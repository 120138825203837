<template>
  <div>
    <van-search
      v-model="keyWord"
      shape="round"
      :placeholder="$t('输入关键字搜索')"
      @search="search"
      @input="onValueChanged"
    />
    <van-cell-group
      v-if="recentSeach && recentSeach.length && (!keyWord || !searched)"
      :title="$t('Recent Search')"
      style="background-color: white"
    >
      <div v-for="item in recentSeach" :key="item" class="recent-search">
        <van-icon name="clock-o" />
        <span class="rencent-content" @click="recentClick(item)">{{
          item
        }}</span>
        <van-icon name="close" @click="removeRecent(item)" />
      </div>
    </van-cell-group>

    <van-pull-refresh
      v-if="keyWord && searched"
      v-model="refreshing"
      @refresh="onRefresh"
      style="margin-top: 10px"
      :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')"
      :loading-text="$t('加载中...')"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        style="
                margin-bottom: 50px;height:100%'
            "
        :finished-text="products.length ? $t('没有更多了') : ''"
        @load="onLoad"
      >
        <van-grid
          v-if="products && products.length"
          :column-num="2"
          :gutter="10"
        >
          <van-grid-item
            v-for="product in products"
            :key="product.id"
            @click="toProduct(product.id)"
          >
            <van-image
              width="100%"
              height="150px"
              :src="product.featuredImage ? product.featuredImage.url : ''"
              fit="cover"
            />
            <div class="product-name">
              {{
                product.local_title && product.local_title.value
                  ? product.local_title.value
                  : product.title
              }}
            </div>
            <div class="product-price" style="font-size: 0.9rem">
              <span style="flex: 1; font-size: 1rem" class="baht-price"
                >฿{{ getPrice(product) }}</span
              >
              <span>{{
                product.compare_at_platform_price
                  ? "Shopee:฿" + product.compare_at_platform_price.value
                  : " "
              }}</span>
            </div>
            <div class="product-price">
              <span style="flex: 1; font-size: 1rem"> </span>
              <span>
                {{
                  product.compare_product_sold
                    ? product.compare_product_sold.value + "sold"
                    : " "
                }}
              </span>
            </div>
          </van-grid-item>
        </van-grid>
        <van-empty
          v-if="!products.length"
          image="search"
          :style="{}"
          :description="$t('暂无数据')"
        />
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  Search,
  Icon,
  CellGroup,
  PullRefresh,
  Grid,
  GridItem,
  Empty,
  Toast,
  Image,
  List,
} from "vant";
import { searchProducts } from "./service";
import { getRecentSearch, storeRecentSearch } from "@/utils/global";
import qs from "qs";
import { formatPrice } from "@/utils/utils";

export default {
  components: {
    [Search.name]: Search,
    [Icon.name]: Icon,
    [CellGroup.name]: CellGroup,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Empty.name]: Empty,
    [PullRefresh.name]: PullRefresh,
    [Image.name]: Image,
    [List.name]: List,
  },
  data() {
    return {
      keyWord: "",
      recentSeach: [],
      products: [],
      refreshing: false,
      searched: false,
      pageSize: 10,
      pageInfo: null,
      finished: true,
      loading: false,
    };
  },
  methods: {
    onLoad() {
      this.loading = true;
      this.getData();
    },
    recentClick(item) {
      this.keyWord = item;
      this.search();
    },
    removeRecent(item) {
      const index = this.recentSeach.indexOf(item);
      this.recentSeach.splice(index, 1);

      storeRecentSearch(this.recentSeach);
    },
    onValueChanged() {
      if (!this.keyWord) {
        this.searched = false;
      }
    },
    onRefresh() {
      if (!this.keyWord) {
        this.refreshing = false;
        return;
      }
      this.search();
    },
    toProduct(id) {
      this.$router.push({
        name: "Product",
        query: { id },
      });
    },
    getPrice(product) {
      const { priceRange } = product;
      if (!priceRange) return 0;
      const minPrice = priceRange.minVariantPrice.amount;
      return formatPrice(minPrice / 100);
    },
    isAli1688Product(vendor) {
      return "ali1688" === vendor;
    },
    getMetaFields(metafields) {
      if (!metafields) return {};

      let fields = {};
      metafields.edges.forEach((field) => {
        const { key, value } = field.node;
        fields[key] = value;
      });
      return fields;
    },
    search() {
      if (!this.keyWord) return;

      this.pageInfo = null;
      if (!this.recentSeach.includes(this.keyWord))
        this.recentSeach.unshift(this.keyWord);
      storeRecentSearch(this.recentSeach);

      this.getData();
    },
    async getData() {
      let after;
      if (this.pageInfo && this.pageInfo.endCursor)
        after = this.pageInfo.endCursor;

      let payload = {
        first: this.pageSize,
        query: this.keyWord,
        returnsQlStr: `{
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                      endCursor
                    }
                    edges {
                      node {
                        id
                        title
                        vendor
                        featuredImage {
                          url
                        }
                        priceRange {
                          minVariantPrice {
                            amount
                          }
                        }
                        local_title:metafield(namespace:\\"custom\\",key:\\"local_title\\"){
                          value
                        }
                        compare_at_platform_price:metafield(namespace:\\"products\\",key:\\"compare_at_platform_price\\"){
                          value
                        }
                        compare_product_sold:metafield(namespace:\\"products\\",key:\\"compare_product_sold\\"){
                          value
                        }
                      }
                    }
                }`,
      };

      if (after) {
        payload.after = after;
      }

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });
      const res = await searchProducts(qs.stringify(payload));
      Toast.clear();
      const { products } = res.data;
      this.searched = true;
      this.refreshing = false;
      this.loading = false;
      if (products) {
        const { edges, pageInfo } = products;

        if (!this.pageInfo) this.products = [];

        const pageData = edges.map((item) => {
          let product = item.node;
          product.metafields = this.getMetaFields(product.metafields);
          return product;
        });
        this.products = this.products.concat(pageData);

        this.pageInfo = pageInfo;
        this.finished = !pageInfo.hasNextPage;
      }
    },
    getRecentSearch() {
      const recent = getRecentSearch();
      if (recent) {
        const keywords = recent.split(",");
        this.recentSeach = keywords;
      }
    },
  },
  mounted() {
    this.getRecentSearch();
  },
};
</script>
<style lang="less" scoped>
.recent-search {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.rencent-content {
  flex: 1;
  display: inline-block;
  padding: 0px 10px;
  font-size: 0.9rem;
}

.van-cell-group__title {
  background-color: white;
}

.product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  text-align: start;
  margin: 5px 0px;
  font-size: 0.9rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

:deep(.van-grid-item__content--center) {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: start !important;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.product-price {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.8rem;
  min-height: 0.8rem;
}

.product-sold {
  float: right;
}

.baht-price {
  color: red;
}
</style>